import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useAuth from '../../hooks/useAuth';
import MealMenu from '../../components/Meal/MealMenu';
//import MealAttendance from '../../components/Meal/MealAttendance';
import MenuFooter from '../../components/MenuFooter';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Badge } from '@mui/material';


const HomePage = () => {
    const navigate = useNavigate();
    const param = useParams()
    const { setModal, setShowModal, auth, setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const [scenario, setScenario] = useState('no-answer');
    const [,setCalendarDisplay] = useState(false);
    const [mealsDisplay, setMealsDisplay] = useState(false);
    const [meals, setMeals] = useState(null);
    const [, setSlots] = useState([]);
    const [userAllergens, setUserAllergens] = useState();
    const [userDietaryConstraints, setUserDietaryConstraints] = useState();
    const [menuId, setMenuId] = useState('0');
    const [total, setTotal] = useState(0);
    const axiosPrivate = useAxiosPrivate();
    const [reservations, setReservations] = useState([])
    const [, setTodayReservation] = useState(null)
    const [, setTodayComing] = useState(false)
    const [, setReservationInfos] = useState([])
    const [, setReservationPlatter] = useState([])
    const [, setReservationMeal] = useState([])
    const [, setReservationAccomp] = useState([])
    const [, setReservationCheese] = useState([])
    const [, setReservationDessert] = useState([])
    const [displayScenario, setDisplayScenario] = useState(false);
    const [, setExpirationDays] = useState(0);
    const [dayLimit, setDayLimit] = useState(0);
    const [, setLimitBreakDay] = useState(0);
    const { reset, register, handleSubmit, getValues, setValue } = useForm();
    const completeDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    const completeMonths = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
    const [cookie, setCookie] = useCookies({});
    const canteenId = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id'] ?? null
    const [unsolds, setUnsolds] = useState(true);

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');

    const coming = async (come, dayAfter) => {
        let nextDay = dayjs(dayAfter)
        while( nextDay.format('d') == 6 || nextDay.format('d') == 3 || nextDay.format('d') == 0 ){
            nextDay = dayjs(nextDay).add(1, 'day')
        }
        dayAfter = nextDay.format('YYYY-MM-DD')

        document.querySelector('#chosen-not-coming-ok').classList.add('not-coming-active')
        setIsLoading(true);

        if( scenario == 'no-answer' ){
            await axiosPrivate.post('/guests/reservation/meal', {date: currentDate, reserved: come, canteenId: canteenId}, { withCredentials: true })
            if (null !== auth.childId) {
                let child = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_child`];
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {id: child.id, name: child.name, point: child.point + 10 }, { path: '/' })
            } else {
                setAuth({ ... auth, jabuPoint: auth.jabuPoint + 10});
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ... auth, jabuPoint: auth.jabuPoint + 10 }, { path: '/' });
            }
        }else{
            await axiosPrivate.put('/guests/reservation/meal', {date: currentDate, reserved: come, canteenId: canteenId}, { withCredentials: true })
        }

        if( !come ){
            setScenario('no-answer')
            window.location = `/meal/${dayAfter}`;
        }else{
            setScenario('he-comes')
            window.location = `/meal/${currentDate}`;
        }


        setIsLoading(false);
    }

    let params = useParams()
    let today = new Date()
    let todayjs = dayjs()
    const dayLimitDate = dayjs(dayLimit);
    let currentDate = params?.date ? params.date : today.toJSON().slice(0, 10)
    let slotType = params?.type ? params.type : 1
    let currentDay = new Date(currentDate)
    let aft = new Date(currentDate)
    let dayAfter = new Date(aft.setDate(aft.getDate() + 1))
    //const slots = ['10:15', '12:00', '12:30', '13:00', '13:30']

    const getReservations = async (dayLimitt) => {
        setIsLoading(true);
        try {     
            const result = await axiosPrivate.get(`/guests/reservation/meal?currentDate=${currentDate}&date=${dayjs(dayLimitt).format('YY-MM-DD')}&canteenId=${canteenId}`, { withCredentials: true })
            const reservationsData = result.data['dates']
            setReservations(reservationsData)
            setTodayReservation(reservationsData[currentDate]['reserved'])
            setTodayComing(reservationsData[currentDate]['here'])

            if( null == reservationsData[currentDate]['reserved'] ){
                setScenario('no-answer')
            }

            if( true == reservationsData[currentDate]['reserved'] ){
                setScenario('he-comes')
            }

            if( false == reservationsData[currentDate]['reserved'] ){
                setScenario('he-dont-come')
            }

            if( currentDay < dayLimitt ){
                setScenario('vote-expiration')
            }

            setCalendarDisplay(true)
            setIsLoading(false);
        } catch (error) {
            console.log(error)
            if('Canteen not exist' == error.response.data['hydra:description']){
                setScenario('no-canteen')
            }
        }
    };

    const getCanteenInformations = async () => {
        try {     
            const result = await axiosPrivate.get('/guests/canteen', {
                params: {
                    childId: auth.childId,
                },
                withCredentials: true,
            })

            setExpirationDays(result.data.canteen.daysLimitBreak)
            let limit = new Date()
            limit.setHours(0, 0, 0, 0)
            let breakDay = new Date()
            breakDay.setHours(0, 0, 0, 0)
            let dayLimitt = new Date(limit.setDate(limit.getDate() + result.data.canteen.daysLimitBreak))
            setDayLimit(dayLimitt)
            setLimitBreakDay(new Date(breakDay.setDate(breakDay.getDate() + result.data.canteen.daysLimitBreak + 1)))

            if (params?.date == null) {
                var year = dayLimitt.getFullYear();
                var month = ('0' + (dayLimitt.getMonth() + 1)).slice(-2);
                var day = ('0' + dayLimitt.getDate()).slice(-2);
                var formattedDate = year + '-' + month + '-' + day;

                window.location = `/meal/${formattedDate}`;
            }

            return dayLimitt
        } catch (error) {
            console.log(error)
        }
    };

    const handleDateChange = (date) => {
        window.location = `/meal/${date.format('YYYY-MM-DD')}`;
    };

    const shouldDisableDate = (date) => {
        return date.isBefore(dayLimitDate) || undefined === reservations[date.format('YYYY-MM-DD')] || reservations[date.format('YYYY-MM-DD')].closed
    };

    const getMeals = async () => {
        try {     
            const result = await axiosPrivate.get(`/guests/canteen/menu?date=${currentDate}&canteenId=${canteenId}&slotType=${slotType}`, { withCredentials: true })
            setMeals(result.data)
            setMenuId(result.data.infos.menu_id)

            let timeSlots = []
            result.data.stats.forEach((stat) => {
                timeSlots.push(stat.slot)
            })

            setSlots(timeSlots)

            const responseUser = await axiosPrivate.get('/guests/setting', {
                params: {
                    childId: auth.childId,
                },
                withCredentials: true,
            });
            setUserAllergens(responseUser.data.allergens)
            setUserDietaryConstraints(responseUser.data.dietaryConstraints)
        } catch (error) {
            console.log(error)
            if(404 == error.response.status && 'Canteen not exist' == error.response.data['hydra:description']){
                setScenario('no-canteen')
            }else{
                if( 404 == error.response.status ){
                    setScenario('no-menu')
                }
            }        
        }
        setIsLoading(false);
        setDisplayScenario(true)
        setMealsDisplay(true);
    };

    useEffect(() => {
        if (auth?.canteenId === null && auth?.childId === null) {
            navigate('/register-model');
        }
    }, []);

    useEffect(() => {
        setDisplayScenario(false)
        setIsLoading(true)

        if( null != canteenId ){
            getUnsolds()
            getCanteenInformations().then((dayLimitt) => getReservations(dayLimitt).then(() => getMeals().then()));
        }else{
            setScenario('no-canteen')
            setIsLoading(false);
            setDisplayScenario(true)
        }
    }, [param.date, param.type]);

    useEffect(() => {
        getUserReservations();
    }, [menuId, param.date, param.type, scenario]);

    const getUserReservations = async () => {
        setIsLoading(true);
        try {
            const result = await axiosPrivate.get(`/guests/reservation/menu/meal?menu_id=${menuId}&canteenId=${canteenId}`, { withCredentials: true })
            setReservationInfos(result.data)
            setReservationPlatter(result.data.mealsInformations.find((meal) => meal.type == 1)?.name)
            setReservationMeal(result.data.mealsInformations.find((meal) => meal.type == 2)?.name)
            setReservationAccomp(result.data.mealsInformations.find((meal) => meal.type == 4)?.name)
            setReservationCheese(result.data.mealsInformations.find((meal) => meal.type == 5)?.name)
            setReservationDessert(result.data.mealsInformations.find((meal) => meal.type == 3)?.name)
            setTotal(result.data?.total);
            reset({
                dishes: result.data?.meals.map((meal) => String(meal)) ?? [],
                attendance: result.data?.time.slice(0,5),
            });

            if( result.data?.meals.length > 0 ){
                document.querySelector('.meal-submit').removeAttribute('disabled')
            }
        } catch (error) {
            setReservationInfos([])
            reset({
                dishes: [],
                attendance: null,
            });
        }
        setIsLoading(false);
    };

    // Call Submit Canteen Setting Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Check Data
        if (data.dishes.length === 0) {
            setFlash({ message: 'Veuillez sélectionner au moins un plat'});
            setShowFlash(true);
            setIsLoading(false);

            return;
        }
        /*if (data.attendance === null) {
            setFlash({ message: 'Veuillez sélectionner un créneau'});
            setShowFlash(true);
            setIsLoading(false);

            return;
        }*/

        //Call API
        try {
            let pointsToEarn = 10;
            let response = ''
            response = await axiosPrivate.post('/guests/reservation/menu/meal', {
                menu_id: menuId,
                //reserved_hour: data.attendance + ':00',
                reserved_hour: '12:00:00',
                dishes: data.dishes.map((dish) => parseInt(dish)),
                canteenId: canteenId
            }, {withCredentials: true});

            let diffDays = 0;
            diffDays = Math.round((currentDay.getTime() - today.getTime()) / (1000 * 3600 * 24));
            if( diffDays < 7 ){
                pointsToEarn = 5;
            }

            if( diffDays >= 14 && diffDays < 21 ){
                pointsToEarn = 20;
            }

            if( diffDays >= 21 && diffDays < 28 ){
                pointsToEarn = 30;
            }

            if( diffDays >= 28 && diffDays < 35 ){
                pointsToEarn = 40;
            }

            if( diffDays >= 35 ){
                pointsToEarn = 50;
            }

            if( false == response.data['update'] ){
                let userPoints = ''
                if ('undefined' !== cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]){
                    userPoints = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['point'] ?? 0
                    setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, { ... cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_child`], point: userPoints + pointsToEarn }, { path: '/' });
                } else {
                    userPoints = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint'] ?? 0
                    setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ... auth, jabuPoint: userPoints + pointsToEarn }, { path: '/' });
                }
            }

            // setFlash({ message: 'Vote effectué', type: 'success' });
            // navigate(`/meal/${dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD')}`);

            setModal({content: <div style={{padding: '20px 10px', backgroundColor:'white'}}>
                <div className='meal-header' style={{position: 'relative', fontWeight: 600, backgroundColor: 'transparent', fontSize: '24px', justifyContent: 'center'}}>
                    C&apos;est noté merci !
                </div>
            
                <div className='review-modal-title'>
                    <div style={{justifyContent: 'center', display: 'flex'}}><i style={{color:'#008059', fontSize: '90px'}} className="fa-solid fa-circle-check"></i></div>
                </div>

                {( false == response.data['update'] ) && <div className='review-modal-title' style={{justifyContent: 'center', display: 'flex', color: 'black'}}>
                    <div className='header-points' style={{backgroundColor: 'white'}}>
                        <img style={{width: '40px'}} src='/images/points-icon.png' alt='points'></img>
                        <span style={{fontSize: '17px'}}>+ {pointsToEarn}</span>
                    </div>
                </div>}

                <Link onClick={() => setShowModal(false)} to={`/meal/${dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD')}`} className='d-flex justify-content-center review-modal-title' style={{color: 'black', textAlign: 'center'}}>
                    <button className='meal-submit border-0' style={{width: 'auto'}}>Passer au jour suivant</button>
                </Link>
            </div>})
            setShowModal(true)
        } catch (err) {
            console.log(err);
        }

        // Flash Message
        //setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    const getUnsolds = async () => {
        try {    
            const result = await axiosPrivate.get(`/guests/canteen/unsolds?canteenId=${canteenId}&requestedDate=${currentDate}`, { withCredentials: true })
            setUnsolds(result.data)
        } catch (error) {
            setUnsolds({remains:null})
        }
    }

    const current = dayjs(currentDate);

    const dateBefore = () => {
        let dateBef = current.add(-1, 'day')
        if( dateBef.format('d') == 3 ){
            dateBef = dateBef.add(-1, 'day')
        }

        if( dateBef.format('d') == 0 ){
            dateBef = dateBef.add(-2, 'day')
        }

        window.location = `/meal/${dateBef.format('YYYY-MM-DD')}`;
    };

    const dateAfter= () => {
        let dateAft = current.add(1, 'day')
        if( dateAft.format('d') == 3 ){
            dateAft = dateAft.add(1, 'day')
        }

        if( dateAft.format('d') == 6 ){
            dateAft = dateAft.add(2, 'day')
        }

        window.location = `/meal/${dateAft.format('YYYY-MM-DD')}`;
    };

    const showOnlyMinCO2 = ()=> {
        document.querySelector('#filter-all').classList.remove('filter-active')
        document.querySelector('#filter-co2').classList.add('filter-active')

        document.querySelectorAll('.meal-cardo').forEach((element) => {
            if(! element.classList.contains('co2_min')){
                element.parentElement.classList.add('d-none')
            }
        })
    }

    const showAll = ()=> {
        document.querySelector('#filter-co2').classList.remove('filter-active')
        document.querySelector('#filter-all').classList.add('filter-active')

        document.querySelectorAll('.meal-cardo').forEach((element) => {
            element.parentElement.classList.remove('d-none')
        })
    }

    const showCalendar = () => {
        document.querySelector('.MuiInputBase-inputAdornedEnd').click()
    }

    const notComingActive = ('he-dont-come' == scenario) ? 'not-coming-active' : ''

    if('no-menu' == scenario){
        return ( displayScenario &&
            <div className='home-page d-flex min-vh-100 justify-content-center'>
                <div className='meal-header'>
                    <div className='d-flex'>
                        <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                    </div>
                    
                    <div className='presence-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                    
                    <div className='d-flex'>
                        <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                    </div>
                </div>
    
                <div role='presentation' id='meal-infos' style={{alignItems:'initial'}}>
                    <div className='d-flex justify-content-center'><img style={{width: '150px'}} src='/images/unsold-chef.png' alt='Invendus'></img></div>
                    <p style={{fontSize: '30px'}} className='px-4 text-center'>Fermé</p>
                </div>
    
                <MenuFooter unsoldsRemain={unsolds.remains}></MenuFooter>
            </div>
        );
    }else{
        return ( displayScenario &&
            <div className='home-page d-flex min-vh-100 justify-content-center'>
                <div className='meal-header'>
                    <div className='d-flex'>
                        <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                    </div>
                    
                    <div className='presence-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>

                    <span role='presentation' onClick={showCalendar} className='material-symbols-outlined'>calendar_month</span>

                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                        <DatePicker
                            onChange={handleDateChange}
                            minDate={todayjs.add(-10, 'days')}
                            maxDate={dayLimitDate.add(60, 'days')}
                            defaultValue={current}
                            loading={false}
                            shouldDisableDate={shouldDisableDate}
                            slots={{
                                day: ServerDay,
                            }}
                            slotProps={{
                                day: {
                                    reservations,
                                },
                            }}
                        />
                    </LocalizationProvider>
                    
                    <div className='d-flex'>
                        <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                    </div>
                </div>

    
                <div role='presentation' id='meal-infos' style={{alignItems:'initial', marginTop: '60px'}}>
                    { ( currentDay < dayLimit ) && <div className='d-flex justify-content-center'>
                        <span style={{width:'88px'}} className='home-vote-closed'>Votes clos</span>
                    </div> }

                    { ( currentDay >= dayLimit && 'he-comes' == scenario ) && <div className='d-flex justify-content-center'>
                        <span style={{width:'145px'}} className='home-vote-confirmed'>Vote enregistré</span>
                    </div> }

                    <div className='coming-choice d-flex justify-content-center flex-grow-1 flex-shrink-1 flex-column'>
                        { ( currentDay >= dayLimit ) && <div className='d-flex justify-content-around flex-grow-1' style={{maxHeight: '100px'}}>
                            <button id='chosen-not-coming-ok' className={`${notComingActive}`} onClick={ () => coming(false, dayAfter.toJSON().slice(0, 10)) }><i style={{'fontSize': '16px', 'color': 'red'}} className="fa-solid fa-xmark"></i> <span style={{fontSize: '14px'}}>Je ne viens pas</span></button>
                        </div> }

                        { '' !== notComingActive &&
                            <div style={{margin: '10px 0', padding: '10px', borderRadius: '10px', display: 'flex', flex:1, justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                <span style={{marginBottom: '20px'}}>Tu as indiqué ton absence pour ce jour</span>
                                <button id='chosen-coming-ok' style={{backgroundColor: 'white'}} onClick={ () => coming(true, dayAfter.toJSON().slice(0, 10)) }><span>J&apos;ai changé d&apos;avis</span></button>
                            </div>
                        }
                    </div>

                    { '' === notComingActive && <>
                        <div className='d-flex flex-row justify-content-center w-100'>
                            <div className='meal-filters d-flex flex-row justify-content-center flex-grow-1 flex-shrink-1'>
                                <div id='filter-all' role='presentation' onClick={showAll} className='filter-active d-flex flex-column justify-content-center align-items-center'>
                                    <div><i style={{fontSize: '20px'}} className="fa-solid fa-utensils"></i></div>
                                    <div style={{fontSize: '13px'}}>Tout</div>
                                </div>

                                <div id='filter-co2' role='presentation' onClick={showOnlyMinCO2} className='d-flex flex-column justify-content-center align-items-center'>
                                    <div>
                                        <span style={{fontWeight: 'bold', fontSize: '20px'}} className='material-symbols-outlined'>cloud_off</span>
                                    </div>
                                    <div style={{fontSize: '13px', textAlign: 'center'}}>Moins de CO2</div>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={ handleSubmit(submit) } >
                            <MealMenu userAllergens={userAllergens} userDietaryConstraints={userDietaryConstraints} getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} mealsDisplay={mealsDisplay} meals={meals?.meals} date={currentDate}></MealMenu>
        
                            {/*<MealAttendance slotType={slotType} slots={slots} stats={meals.stats} register={register} total={total}></MealAttendance>*/}
        
                            <div className='confirm-meal'>
                                { ( currentDay >= dayLimit ) && <button type="submit" className='meal-submit border-0'>Valider</button> }
                            </div>
                        </form>
                    </>}
                </div>
    
                <MenuFooter unsoldsRemain={unsolds.remains}></MenuFooter>
            </div>
        );
    }
};

export default HomePage;

function ServerDay(props) {
    const { reservations = [], day, outsideCurrentMonth, ...other } = props;

    let badgeContent = undefined;
    let color = 'default';
    if (!props.outsideCurrentMonth && undefined !== reservations[day.format('YYYY-MM-DD')]) {
        if (true === reservations[day.format('YYYY-MM-DD')].reserved) {
            badgeContent = ' ';
            color = 'success';
        } else if (false === reservations[day.format('YYYY-MM-DD')].reserved) {
            badgeContent = ' ';
            color = 'error';
        }
    }

    return (
        <Badge key={props.day.toString()} overlap="circular" badgeContent={badgeContent} variant='dot' color={color}>
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </Badge>
    );
}
