import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import LoginPage from './pages/Login/Login';
import ResetPasswordPage from './pages/Login/ResetPassword';
import ForgotPasswordPage from './pages/Login/ForgotPassword';
import RegisterPage from './pages/Login/Register';
import HomePage from './pages/Guest/Home';
import MyHomePage from './pages/Guest/MyHome';
import ChallengePage from './pages/Guest/Challenge';
import ServerErrorPage from './pages/Error/ServerError';
import NotFoundPage from './pages/Error/NotFound';
import UnauthorizedPage from './pages/Error/Unauthorized';
import UserPoints from './pages/Guest/UserPoints';
import UserProfile from './pages/Guest/UserProfile';
import UserInfos from './pages/Guest/UserInfos';
import UserCanteens from './pages/Guest/UserCanteens';
import UserAddCanteen from './pages/Guest/UserAddCanteen';
import UserChangePassword from './pages/Guest/UserChangePassword';
import UserConstraints from './pages/Guest/UserConstraints';
import UserHistoryPage from './pages/Guest/UserHistory';
import LogoutPage from './pages/Login/Logout';
import Unsolds from './pages/Guest/Unsolds';
import UnsoldsCart from './pages/Guest/UnsoldsCart';
import News from './pages/Guest/News';
import ConfidentialPage from './pages/Guest/Confidential';
import CGUPage from './pages/Guest/CGU';
import HelperPage from './pages/Guest/Helper';
import QRCodePresence from './pages/Guest/QRCodePresence';
import ReservationConfirmation from './pages/Guest/ReservationConfirmation';
import TutorialPage from './pages/TutorialPage';
import StayTunedPage from './pages/StayTunedPage';
import UserAllergens from './pages/Guest/UserAllergens';
import UserMealFavorites from './pages/Guest/UserMealFavorites';
import MealReview from './pages/Guest/MealReview';
import UserDeleteCanteens from './pages/Guest/UserDeleteCanteens';
import LoginPasswordPage from './pages/Login/LoginPassword';
import CallBackPage from './pages/Login/CallBack';
import CallBackRegisterPage from './pages/Login/CallBackRegister';
import RegisterPasswordPage from './pages/Login/RegisterPassword';
import UserPaymentPage from './pages/Guest/UserPayment';
import MyQRCode from './pages/Guest/MyQRCode';
import UserInvitation from './pages/Guest/UserInvitation';
import UserModel from './pages/Guest/Register/UserModel';
import RegisterCanteenPage from './pages/Guest/Register/RegisterCanteen';
import RegisterChildren from './pages/Guest/Register/RegisterChildren';
import ConfirmFamily from './pages/Guest/Register/ConfirmFamily';
import UserFamily from './pages/Guest/UserFamily';
import RegisterFamily from './pages/Guest/Register/RegisterFamily';
import UserAddChild from './pages/Guest/UserAddChild';
import UserDeleteChild from './pages/Guest/UserDeleteChild';
import UserUpdateChild from './pages/Guest/UserUpdateChild';
import SendActivatePage from './pages/Login/SendActivate';
import ActivatePage from './pages/Login/Activate';
import ChoiceFamily from './pages/Guest/Register/ChoiceFamily';
import UserReview from './pages/Guest/UserReview';
import KcalDetails from './pages/Guest/KcalDetails';
import CO2Details from './pages/Guest/CO2Details';

const Routing = () => {
    return (
        <Routes>
            {/* Public Routes */}
            <Route path="/login/sso" element={ <LoginPage/> }></Route>
            <Route path="/login" element={ <LoginPasswordPage/> }></Route>
            <Route path="/:type/auth/callback" element={ <CallBackPage/> }></Route>
            <Route path="/:type/auth/register/callback" element={ <CallBackRegisterPage/> }></Route>
            <Route path="/register/sso" element={ <RegisterPage/> }></Route>
            <Route path="/register" element={ <RegisterPasswordPage/> }></Route>
            <Route path="/forgot-password" element={ <ForgotPasswordPage/> }></Route>
            <Route path="/reset-password/:email/:token" element={ <ResetPasswordPage/> }></Route>
            <Route path="/unauthorized" element={ <UnauthorizedPage/>} />
            <Route path="/tutorial" element={ <TutorialPage/>} />
            <Route path="/tutorial/:step" element={ <TutorialPage/>} />
            <Route path="/stay-tuned" element={ <StayTunedPage/>} />
            <Route path="/confidential" element={ <ConfidentialPage/> }></Route>
            <Route path="/cgvu" element={ <CGUPage/> }></Route>
            <Route path="/send-activate" element={ <SendActivatePage/> }></Route>
            <Route path="/activate/:email/:token" element={ <ActivatePage/> }></Route>
            <Route path="/help" element={ <HelperPage/> }></Route>

            {/* Routes Guest */}
            <Route element={<RequireAuth allowedRoles={['ROLE_GUEST']} />}>
                <Route path="/register-model" element={ <UserModel/> }></Route>
                <Route path="/choice-family" element={ <ChoiceFamily/> }></Route>
                <Route path="/register-canteen" element={ <RegisterCanteenPage/> }></Route>
                <Route path="/register-children" element={ <RegisterChildren/> }></Route>
                <Route path="/register-family" element={ <RegisterFamily/> }></Route>
                <Route path="/confirm-family" element={ <ConfirmFamily/> }></Route>
                <Route path="/meal" element={ <HomePage/> }></Route>
                <Route path="/" element={ <MyHomePage/> }></Route>
                <Route path="/meal/:date" element={ <HomePage/> }></Route>
                <Route path="/meal/:date/:type" element={ <HomePage/> }></Route>
                <Route path="/meal-review/:date" element={ <MealReview/> }></Route>
                <Route path="/reservation/confirm" element={ <ReservationConfirmation/> }></Route>
                <Route path="/my-points" element={ <UserPoints/> }></Route>
                <Route path="/challenge" element={ <ChallengePage/> }></Route>
                <Route path="/review" element={ <UserReview/> }></Route>
                <Route path="/review/:date" element={ <UserReview/> }></Route>
                <Route path="/my-profile" element={ <UserProfile/> }></Route>
                <Route path="/my-infos" element={ <UserInfos/> }></Route>
                <Route path="/my-canteens" element={ <UserCanteens/> }></Route>
                <Route path="/delete-canteens" element={ <UserDeleteCanteens/> }></Route>
                <Route path="/add-canteen" element={ <UserAddCanteen/> }></Route>
                <Route path="/my-password" element={ <UserChangePassword/> }></Route>
                <Route path="/my-constraint" element={ <UserAllergens/> }></Route>
                <Route path="/my-dietetary" element={ <UserConstraints/> }></Route>
                <Route path="/my-invitation" element={ <UserInvitation/> }></Route>
                <Route path="/my-favorite-meals" element={ <UserMealFavorites/> }></Route>
                <Route path="/logout" element={ <LogoutPage/> }></Route>
                <Route path="/unsolds-cart" element={ <UnsoldsCart/> }></Route>
                <Route path="/unsolds" element={ <Unsolds/> }></Route>
                <Route path="/unsolds/:date" element={ <Unsolds/> }></Route>
                <Route path="/news" element={ <News/> }></Route>
                <Route path="/my-history" element={ <UserHistoryPage/> }></Route>
                <Route path="/my-payments" element={ <UserPaymentPage/> }></Route>
                <Route path="/qr-code-presence" element={ <QRCodePresence/> }></Route>
                <Route path="/my-qr-code" element={ <MyQRCode/> }></Route>
                <Route path="/my-family" element={ <UserFamily/> }></Route>
                <Route path="/add-child" element={ <UserAddChild/> }></Route>
                <Route path="/delete-child" element={ <UserDeleteChild/> }></Route>
                <Route path="/update-child" element={ <UserUpdateChild/> }></Route>
                <Route path="/kcal-details" element={ <KcalDetails/> }></Route>
                <Route path="/kcal-details/:date" element={ <KcalDetails/> }></Route>
                <Route path="/co2-details" element={ <CO2Details/> }></Route>
                <Route path="/co2-details/:date" element={ <CO2Details/> }></Route>
            </Route>

            {/* Routes Error */}
            <Route path="/500" element={ <ServerErrorPage/> }></Route>
            <Route path='/*' element={ <NotFoundPage/> }/>
        </Routes>
    );
};

export default Routing;
