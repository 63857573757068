import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import InputField from '../InputField';

const RegisterPassword = () => {
    // Global
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { setFlash, setShowFlash, setIsLoading } = useAuth();
    // const maxDate = new Date();
    // maxDate.setFullYear(maxDate.getFullYear() - 18);
    // const [startQrCode, decodedQRData] = useQRCodeScan();

    // Form
    // const { refLastName, ...inputPropsLastName } = register('lastName', {
    //     required: 'Nom obligatoire',
    //     maxLength: {
    //         value: 50,
    //         message: 'Maximum 50 caractères',
    //     },
    // });
    // const { refFirstName, ...inputPropsFirstName } = register('firstName', {
    //     required: 'Prénom obligatoire',
    //     maxLength: {
    //         value: 50,
    //         message: 'Maximum 50 caractères',
    //     },
    // });
    // const { refBirthdate, ...inputPropsBirthdate } = register('birthdate', {
    //     maxLength: {
    //         value: 10,
    //         message: 'Format DD/MM/AAAA',
    //     },
    //     // max: {
    //     //     value: maxDate.toISOString().split('T')[0],
    //     //     message: 'Vous devez avoir au moins 18 ans',
    //     // }
    // });
    const { refUsername, ...inputPropsUsername } = register('username', {
        required: 'Adresse email obligatoire',
        maxLength: {
            value: 50,
            message: 'Maximum 50 caractères',
        },
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Mauvais format',
        }
    });
    const { refPassword, ...inputPropsPassword } = register('password', {
        required: 'Mot de passe obligatoire',
        minLength: {
            value: 8,
            message: 'Minimum 8 caractères',
        },
        pattern: {
            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/,
            message: 'Minimum 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial parmis #@$!%*?&',
        }
    });
    const { refRepeatPassword, ...inputPropsRepeatPassword } = register('repeatPassword', {
        validate: (value) => value === watch('password') || 'Mot de passe non identique',
    });

    function checkCGU(manualRedirect = false){
        const cgvuChecked = document.querySelector('input[name="accept-cgvu"]').checked

        if( true == cgvuChecked ){
            if( true == manualRedirect ){
                window.location = '/register/password'
            }
        }else{
            document.querySelector('#error-accept-cgvu').style.display = 'block'
        }

        return cgvuChecked
    }

    // Call Register Form
    const submit = async (data) => {
        let cgu = checkCGU(false)

        if( cgu ){
            // Flash Message
            setShowFlash(false);
            setFlash({});

            // Loader
            setIsLoading(true);

            // Call API
            try {
                const response = await axios.get(`/users/check?&username=${data.username}`, { withCredentials: true });

                if (response.data.exist) {
                    setFlash({ message: 'Email déjà utilisé' });
                    // Flash Message
                    setShowFlash(true);
                } else {
                    await axios.post('/users/register', {
                        gender: null,
                        last_name: data.lastName,
                        first_name: data.firstName,
                        email: data.username,
                        password: data.password,
                        sub: null,
                        type: null,
                        is_chef: false,
                    }, { withCredentials: true }).then(() => {
                        return axios.post('/users/activate/token', {
                            email: data.username,
                        }, {withCredentials: true})
                    });

                    setFlash({ message: 'Compte Créé avec succès. Un Email a été envoyé pour activer votre compte', type: 'success' });
                    navigate('/login');
                }
            } catch (err) {
                let error;
                if (err?.response?.status === 409) {
                    error = 'Email déjà utilisé';
                } else {
                    error = 'Erreur Serveur';
                    navigate('/500');
                }
                setFlash({ message: error });
            }

            // Flash Message
            setShowFlash(true);
            // Loader
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="login-form">
                <span className="login-subtext">S&apos;inscrire</span>
                <form className="d-flex flex-column" onSubmit={ handleSubmit(submit) } >
                    {/*<FormControl>
                        <Controller
                            rules={{ required: true }}
                            control={control}
                            name="gender"
                            render={({ field }) => (
                                <RadioGroup {...field} className="justify-content-center" defaultValue="1" row>
                                    <FormControlLabel value="1" control={<Radio />} label="Homme" />
                                    <FormControlLabel value="2" control={<Radio />} label="Femme" />
                                </RadioGroup>
                            )}
                        />
                    </FormControl>*/}
                    {/* <InputField color="#94928B" label="Prénom" className="login-field" error={!!errors.firstName} helper={errors?.firstName?.message} inputRef={refFirstName} inputProps={inputPropsFirstName}></InputField>
                    <InputField color="#94928B" label="Nom" className="login-field" error={!!errors.lastName} helper={errors?.lastName?.message} inputRef={refLastName} inputProps={inputPropsLastName}></InputField>
                    <InputField color="#94928B" label="Date de naissance" className="login-field" type="date" forceShrink error={!!errors.birthdate} helper={errors?.birthdate?.message} inputRef={refBirthdate} inputProps={inputPropsBirthdate}></InputField> */}
                    <InputField color="#94928B" label="Email" className="login-field" error={!!errors.username} helper={errors?.username?.message} inputRef={refUsername} inputProps={inputPropsUsername}></InputField>
                    <InputField color="#94928B" label="Mot de passe" type="password" className="login-field" error={!!errors.password} helper={errors?.password?.message} inputRef={refPassword} inputProps={inputPropsPassword}></InputField>
                    {/* <div style={{fontSize: '12px', fontWeight: 700, letterSpacing: '0.5px', marginTop: '-5px' }}>8 caractères minimum</div> */}
                    <InputField color="#94928B" label="Confirme ton mot de passe" type="password" className="login-field" error={!!errors.repeatPassword} helper={errors?.repeatPassword?.message} inputRef={refRepeatPassword} inputProps={inputPropsRepeatPassword}></InputField>

                    <div id="error-accept-cgvu">Veuillez accepter les Conditions Generales de Ventes et d’Utilisation</div>

                    <div className='login-subtitle' style={{paddingLeft: '5px', marginTop: '25px'}}>
                        <input type='checkbox' name='accept-cgvu'></input> Je declare avoir lu et accepte les <Link to="/cgvu" className="text-decoration-underline">Conditions Generales de Ventes et d’Utilisation</Link>
                    </div>

                    <div className='login-subtitle' style={{marginTop: '5px'}}>
                        Acceder a notre <Link to="/confidential" className="text-decoration-underline">Politique de Confidentialité</Link>
                    </div>

                    <button className="login-submit" type="submit">S&apos;inscrire</button>
                </form>

                <div className="login-subtitle">
                    Tu as déjà un compte ? &nbsp;<Link to="/login" className="text-decoration-underline">Se connecter</Link>
                </div>
            </div>
        </>
    );
};

export default RegisterPassword;
