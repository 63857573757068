import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';

const UserCanteens = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#F5F4ED');
   
    // Global
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [displayUserCanteen, setDisplayUserCanteen] = useState(false);
    const [canteensList, setCanteensList] = useState([]);
    const { auth, setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const [, setCookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]);

    const getCanteens = async () => {
        setIsLoading(true);
        const result = await axiosPrivate.get('/guests/canteen/list', { withCredentials: true });
        setCanteensList(result.data.canteens);
        setDisplayUserCanteen(true);
        setIsLoading(false);
    };

    useEffect(() => {
        // Redirect if not choice
        if (auth?.canteenId === null) {
            navigate('/my-profile');
        } else {
            getCanteens();
        }
    }, []);

    const updateCanteen = async (id) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            const response = await axiosPrivate.put('/guests/canteen', {canteen_id: parseInt(id)},{
                withCredentials: true
            });
            setAuth({ ...auth, canteenId: response.data.canteen_id});
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ...auth, canteenId: response.data.canteen_id }, { path: '/' })
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`, {id: response.data.canteen_id, name: response.data.canteen_name}, { path: '/' })
            setFlash({ message: 'La cantine par défaut a été changé', type: 'success' });
            window.location = '/'
        } catch (err) {
            console.log(err);
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    return (
        <>
            { displayUserCanteen &&
                <div className='profil-page d-flex min-vh-100 justify-content-center flex-column'>
                    <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                        <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                            <div className='d-flex profile-header-back'>
                                <Link to='/my-profile'>
                                    <span style={{borderRadius: '20px', padding: '10px' }}>
                                        <i style={{fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                    </span>
                                </Link>
                            </div>
                            <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Mon profil</div>
                        </div>

                        <div className='d-flex flex-column profile-block'>
                            <div className='user-title d-flex justify-content-center'>
                                Ma cantine
                            </div>

                            <div className='d-flex flex-column align-items-center'>
                                <span><em>Liste des cantines</em> <Link to='/delete-canteens'><img style={{height: '24px', marginLeft: '50px'}} alt="trash" src="/images/trash.png"></img></Link></span>
                                { canteensList.length > 0 ?
                                    canteensList.map((canteen) => (
                                        <div role='presentation' onClick={canteen.default === true ? null : () => updateCanteen(canteen.id)} key={canteen.id} className={canteen.default === true ? 'user-canteen selected' : 'user-canteen' }>
                                            <p className='d-flex align-items-center justify-content-between'>
                                                <span>{canteen.name}</span>

                                                { canteen.default &&
                                                    <i className='default-canteen fa-solid fa-circle-check'></i>
                                                }
                                            </p>
                                        </div>
                                    )) :
                                    <span>Vous n&apos;avez pas encore de cantine</span>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-end flex-column justify-content-center align-items-center'>
                        <button className='user-submit'>
                            <Link className='d-block' to='/add-canteen'>Ajouter une cantine</Link>
                        </button>

                        <Link target='_blank' to='https://3n8auddrcgs.typeform.com/to/hCU6niny'><button type="submit" className='join-canteen canteen' style={{background: 'transparent', color: 'black', border: '0'}}>Inviter ma cantine sur Jabu</button></Link>
                    </div>
                </div>
            }
        </>
    )
}

export default UserCanteens;
